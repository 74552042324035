<template>
  <div class="row mx-auto my-auto" id="carouselNews">
    <div class="col-12">
      <Carousel :perPageCustom="[[0, 1], [720, 2], [1200, 3]]">
        <Slide v-for="(video, index) in locale[lang].BLOG.NEWS.VIDEOS" :key="index">
          <div class="px-2">
            <div class="news-card">
              <div class="video-bg">
                <video v-bind:src="video.url" controls></video>
              </div>
              <p class="video-caption">{{video.caption}}</p>
            </div>
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script>
  import { Carousel, Slide, } from "vue-carousel";
  export default {
    components: {
      Carousel,
      Slide,
    },
    data() {
      return {
      };
    },
  };
</script>

<style>
  .VueCarousel-wrapper {
    overflow: visible!important;
  }
  .VueCarousel-dot--active {
    background-color: #ff0313 !important;
  }

  .news-card {
    background: #ffffff;
    box-shadow: 0px 3px 30px #0000001a;
    border-radius: 20px;
    padding: 18px 18px;
    text-align: justify;
  }

  .video-bg {
    align-items: center;
    background: #010100;
    display: flex;
    height: 400px;
  }

  .video-caption {
    line-height: 1;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    margin: 12px 0;
    position: relative;
  }

  .video-caption::after {
    content: "";
    position: absolute;
    background-color: #ff0313;
    left: 0;
    right: calc(100% - 32px);
    height: 3px;
    bottom: -10px;
  }

  video {
    min-height: 400px;
    max-height: 400px;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    .video-bg {
      height: 300px;
    }
    video {
      min-height: 300px;
      max-height: 300px;
    }
  }
</style>
