<template>
  <div class="container-fluid" @click.prevent="filterBlog(card.category)">
    <div class="bg-img" data-aos="fade-right">
      <img
        class="img-fluid"
        :src="require('@/assets/Images/Blog/' + card.img)"
        alt=""
      />
    </div>
    <div class="name">
      <h2>{{ card.name }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    card: Object,
  },
  methods: {
    filterBlog(category) {
      this.$router.push({ name: "BlogCategory", params: { id: category } });
    },
  },
};
</script>

<style scoped>
.container-fluid {
  /* background-color: #0F1010; */
  margin: 40px 0;
}

.bg-img {
  background: #292929 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 250px;
  height: 250px;
  border-radius: 150px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.bg-img:hover {
  background: #ff0313 0% 0% no-repeat padding-box;
}

.bg-img:hover img {
  filter: invert(1);
}

.name {
  margin-top: 40px;
}

.name h2 {
  font: normal normal 500 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}

.img-fluid {
  object-fit: cover;
}
</style>
