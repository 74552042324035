<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row justify-content-left">
          <div class="col-12 title" data-aos="fade-right">
            <h3>{{locale[lang].BLOG.BLOG_ICONS.TITLE}}</h3>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div
            class="col-lg-3 col-md-6 col-sm-4 col-4"
            data-aos="fade-right"
            v-for="(card, key) in locale[lang].BLOG.BLOG_ICONS.ICONS"
            :key="key"
            :id="'icon' + key"
          >
            <BlogIcon :card="card" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogIcon from "@/components/Blog/BlogIcon.vue";
export default {
  components: {
    BlogIcon,
  },
};
</script>

<style scoped>
.title h3 {
  text-align: left;
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  left: 7%;
  bottom: -15px;
  opacity: 1;
}

@media (max-width: 1024px) {
  #icon0 >>> img,
  #icon3 >>> img,
  #icon4 >>> img {
    height: 41px;
  }

  #icon1 >>> img {
    height: 51px;
  }

  #icon2 >>> img,
  #icon5 >>> img {
    height: 55px;
  }

  #icon6 >>> img {
    height: 48px;
  }

  #icon7 >>> img,
  #icon8 >>> img {
    height: 59px;
  }

  #icon10 >>> img {
    height: 61px;
  }

  #icon12 >>> img {
    height: 44px;
  }

  #icon9 >>> img,
  #icon11 >>> img {
    height: 56px;
  }
}
</style>
