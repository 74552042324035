<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid">
      <div class="row px-md-5" id="blog">
        <BlogTop v-model="keyword" id="top" :mobile="mobile" />
      </div>
      <div class="row" v-if="!!keyword">
        <div class="col-12">
          <div class="container">
            <h3 class="mt-4 font-weight-bold"> {{locale[lang].BLOG.RESULT.TEXT_1}} {{posts.length}} {{locale[lang].BLOG.RESULT.TEXT_2}} </h3>
            <ul>
              <li v-for="post in posts" v-bind:key="post.objectId">
               <a href="javascript:;" @click="$router.push('/' + post.year + '/' + post.slug)">{{post.title}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row" id="return">
        <div class="col-12">
          <a class="read-more" @click="goBack">
            <i class="fas fa-arrow-left"></i>           
            {{locale[lang].BLOG.BLOG_CATE.RETURN}}
          </a>
          <hr />
        </div>
      </div>
      <div class="row" id="articles" v-if="arts.length > 0">
        <ArticlesCard :artInfo="arts" id="article" :numCards="arts.length" v-if="!load" />
        
      </div>
      <div class="row msg-error" id="articles" v-else>
        <div class="col-12">
          <h1>{{locale[lang].BLOG.BLOG_CATE.NOT_FOUND}}</h1>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container" >
      <Footer />
    </div>
  </div>
</template>

<script>
import ArticlesCard from "@/components/Home/ArticlesCards.vue";
import BlogTop from "@/components/Blog/BlogTop.vue";
import defaultMixin from "@/mixins/defaultMixin";
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";
export default {
  mixins: [defaultMixin],
  components: {
    BlogTop,
    ArticlesCard,
    NavBar,
    Footer,
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    arts() {
      return this.$store.state.arts.filter(post => {
          return post.category.trim().replace(" ", "").toLowerCase().includes(this.itemId.trim().replace(" ", "").toLowerCase());
        });
    },
    posts() {
        return this.arts.filter(post => {
          return post.title.trim().replace(" ", "").toLowerCase().includes(this.keyword.trim().replace(" ", "").toLowerCase());
        });
    }
  },
  created() {
    if (this.posts.length == 0) {
      this.$store.dispatch("GET_POSTS")
        .then(() => this.load = false)
        .catch(console.error);
    }
    this.load = false;
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Blog" });
    },
  },
  data() {
    return {
      mobile: false,
      load: true,
      keyword: "",
    };
  },
};
</script>

<style scoped>
.container-fluid {
  position: relative;
}

#articles {
  padding: 5% 8%;
  background-image: url("../assets/Images/Background/Enmascarar grupo 133.png"),
    url("../assets/Images/Background/Trazado 1903.png"),
    url("../assets/Images/Blog/Enmascarar grupo 132.png"),
    url("../assets/Images/Blog/Elipse 117.png"),
    url("../assets/Images/Background/Grupo 691.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 100% 50%, 0% 50%, 0 90%, 7% 5%, 97% 93%;
}

#nav-container {
  background-color: #0f1010!important;
}

.msg-error {
  min-height: 600px;
  background-size: 15% !important;
}

#blog {
  background-image: url("../assets/Images/Background/Grupo 129.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

li {
  text-align: left;
}

.padding {
  padding: 0px 170px;
}

#articles >>> .col-12.title {
  display: none !important;
}

#articles >>> .card {
  height: 94%;
}

#articles >>> .category {
  top: -10%;
}

.read-more {
  text-align: left;
  font: normal normal 600 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #ff0313;
  opacity: 1;
  display: block;
}

#return {
  padding: 5% 14% 0;
}

@media (max-width: 1370px) {
  .padding {
    padding: 0 60px;
  }

  #articles {
    padding: 5% 0;
  }

  #icons {
    padding: 20% 85px 35% 85px;
  }

  #return {
    padding: 5% 4% 0;
  }
}

@media (max-width: 1024px) {
  #blog {
    background-image: url("../assets/Images/Coaches/Enmascarar grupo 217.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0 110%;
    padding: 0 0 25% 0;
  }

  #articles {
    background-image: none;
  }

  #articles >>> .category {
    top: -15%;
  }

  #top {
    padding: 0;
  }

  #top >>> .img-fluid {
    height: auto;
  }

  #top >>> .title-sec h2,
  #top >>> .text-sec p {
    text-align: center;
  }

  #top >>> #search-form {
    display: flex;
    justify-content: center;
  }

  #icons {
    background-image: url("../assets/Images/Blog/Enmascarar grupo 210.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
    padding: 25% 5%;
  }

  #icons >>> .bg-img {
    height: 93px;
    width: 93px;
    border-radius: 50%;
  }

  #icons >>> .title h3 {
    font: normal normal 800 20px/24px Montserrat;
    margin-bottom: 30px;
  }

  #icons >>> .title::after {
    bottom: 10px;
    left: 4%;
  }

  #icons >>> .name {
    margin-top: 15px;
  }

  #icons >>> .container-fluid {
    margin: 20px 0;
  }

  #icons >>> .name h2 {
    font: normal normal 500 12px/14px Montserrat;
  }

  #icons >>> .col-4 {
    padding: 0;
  }

  #articles >>> #cards {
    padding: 0 33px;
  }

  #articles >>> .card-title {
    font: normal normal bold 16px/19px Montserrat;
  }

  #articles >>> .card-text {
    font: normal normal 500 14px/18px Montserrat;
    color: #000000;
  }

  #articles >>> .read-more {
    font: normal normal 600 13px/16px Montserrat;
  }

  #news {
    background-image: none;
  }

  #news .padding {
    padding: 0 25px;
  }

  .news {
    margin-bottom: 50px;
  }

  .news::after {
    right: 45.5%;
    bottom: -20px;
  }

  .news h1 {
    text-align: center;
    font: normal normal 800 20px/24px Montserrat;
    margin-right: 0;
  }
}

@media (max-width: 425px) {
  #top >>> .title-sec h2,
  #top >>> .text-sec p {
    text-align: left;
  }

  #top >>> #search-form {
    display: block;
  }

  .news::after {
    right: 40.5%;
    bottom: -20px;
  }

  #articles >>> #article {
    padding: 0;
  }
}

@media (max-width: 375px) {
  #icons,
  #blog {
    background-size: auto;
  }
}
</style>
