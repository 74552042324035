<template>
  <div class="container-fluid">
    <div class="row" v-if="!mobile">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text">
        <div class="p-1">
          <div class="title-sec">
            <h2>{{locale[lang].BLOG.BLOG_TOP.TITLE}}</h2>
          </div>
          <div class="text-sec">
            <p>{{locale[lang].BLOG.BLOG_TOP.AUTHOR}}</p>
          </div>
          <div class="row" id="search-form">
            <div class="col-12">
              <div class="input-group">
                <input class="form-control" id="search" type="text" :placeholder="locale[lang].BLOG.BLOG_TOP.PLACEHOLDER" v-model="keyword"/>
                <button class="btn">
                  <img class="img-fluid search" src="@/assets/Images/Icon-search@2x.png" alt="search-icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center">
        <div class="p-1">
          <img class="img-fluid" src="@/assets/Images/Blog/Blog-top.gif" alt="img" />
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12">
        <img class="img-fluid" src="@/assets/Images/Blog/Blog-top.gif" alt="img" />
      </div>
      <div class="col-12">
        <div class="title-sec">
          <h2>{{locale[lang].BLOG.BLOG_TOP.TITLE}}</h2>
        </div>
        <div class="text-sec">
          <p>{{locale[lang].BLOG.BLOG_TOP.AUTHOR}}</p>
        </div>
        <div class="container-fluid">
          <div class="row" id="search-form">
            <div class="col-12">
              <div class="input-group">
                <input class="form-control" id="search" type="text" :placeholder="locale[lang].BLOG.BLOG_TOP.PLACEHOLDER" v-model="keyword"/>
                <button class="btn">
                  <img class="img-fluid search" src="@/assets/Images/Icon-search@2x.png" alt="search-icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    mobile: Boolean,
  },
  computed: {
    keyword: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    }
  },
};
</script>

<style scoped>
.text {
  padding: 80px 40px 0px;
}

.title-sec {
  padding-right: 0px;
  margin-bottom: 30px;
}

.title-sec h2 {
  text-align: left;
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.text-sec p {
  text-align: left;
  font: normal normal 500 20px/24px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.text-sec {
  padding: 25px 0px;
}

#search {
  border: none;
  border-radius: 15px;
  height: 74px;
}

#search::placeholder {
  text-align: left;
  font: normal normal 500 18px/19px Montserrat;
  letter-spacing: 0px;
  color: #1e1e1e;
  opacity: 1;
}

#search-form {
  display: block;
}

.input-group {
  height: 74px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
}

.search {
  width: 27px !important;
  height: 27px !important;
}

.img-fluid {
  height: auto;
  max-width: 800px;
  width: 100%;
}

@media (min-width: 1920px) {
  .img-fluid {
    height: 600px;
  }
}

@media (max-width: 425px) {

  .title-sec {
    margin-bottom: 16px;
  }

  .title-sec h2 {
    font: normal normal 800 24px/29px Montserrat;
    margin-left: 27px;
  }

  .text-sec {
    padding: 0;
  }

  .text-sec p {
    font: normal normal 500 14px/18px Montserrat;
    margin-left: 27px;
  }

  .input-group {
    height: 39px;
  }

  #search {
    width: 270px;
    height: 39px;
    border-radius: 15px 0px 0px 15px;
  }

  .input-group img {
    width: 18px !important;
    height: 18px !important;
  }

  #search::placeholder {
    font: normal normal 500 12px/15px Montserrat;
  }
}
</style>
