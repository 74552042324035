<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid">
      <div class="row px-md-5" id="blog">
        <BlogTop v-model="keyword" id="top" :mobile="mobile" />
      </div>
      <div class="row" v-if="!!keyword">
        <div class="col-12">
          <div class="container">
            <h3 class="mt-4 font-weight-bold">{{locale[lang].BLOG.RESULT.TEXT_1}} {{posts.length}} {{locale[lang].BLOG.RESULT.TEXT_2}}</h3>
            <ul>
              <li v-for="post in posts" v-bind:key="post.objectId">
                <a href="javascript:;" @click="$router.push('/' + post.year + '/' + post.slug)">{{post.title}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row" id="articles">
        <ArticlesCard :artInfo="arts" id="article" :numCards="6" v-if="!mobile && !load" />
        <ArticlesCard :artInfo="arts" id="article" :numCards="3" v-else-if="!load" />
      </div>
      <div class="row">
        <BlogIcons id="icons" />
      </div>
      <div class="row" id="news">
        <div class="col-12">
          <div class="news mx-md-5" data-aos="fade-right">
            <h1 class="mb-5">{{locale[lang].BLOG.NEWS.TITLE}}</h1>
          </div>
        </div>
        <div class="col-12">
          <NewsCarousel />
        </div>
      </div>
      <div id="test"></div>
    </div>
    <div class="container-fluid" id="footer-container">
      <Footer />
    </div>
  </div>
</template>

<script>
  import ArticlesCard from "@/components/Home/ArticlesCards.vue";
  import BlogIcons from "@/components/Blog/BlogIcons.vue";
  import BlogTop from "@/components/Blog/BlogTop.vue";
  import NewsCarousel from "@/components/Blog/NewsCarousel.vue";
  import defaultMixin from "@/mixins/defaultMixin";
  import NavBar from "@/components/essential/NavBar.vue";
  import Footer from "@/components/essential/Footer.vue";
  export default {
    name: "Blog",
    mixins: [defaultMixin],
    components: {
      ArticlesCard,
      BlogIcons,
      BlogTop,
      NewsCarousel,
      NavBar,
      Footer,
    },
    created() {
      if (this.arts.length == 0) {
        this.$store.dispatch("GET_POSTS")
          .then(() => this.load = false)
          .catch(console.error);
      }
      this.load = false;
    },
    data() {
      return {
        load: true,
        keyword: "",
      };
    },
    computed: {
      arts() {
        return this.$store.state.arts;
      },
      posts() {
        return this.arts.filter(post => {
          return post.title.trim().replace(" ", "").toLowerCase().includes(this.keyword.trim().replace(" ", "").toLowerCase());
        });
      }
    },
  };
</script>

<style scoped>
.container-fluid {
  position: relative;
}

#nav-container {
  background-color: #0f1010!important;
}

#icons {
  background-image: url("../assets/Images/Blog/Trazado 2407.png"),
    url("../assets/Images/Blog/Enmascarar grupo 131.png");
  background-repeat: no-repeat;
  background-size: auto, 100% 96%;
  background-position: 50% 100%, 0 0;
  padding: 15% 170px;
}

#articles {
  padding: 5% 8%;
  background-image: url("../assets/Images/Background/Enmascarar grupo 133.png"),
    url("../assets/Images/Background/Trazado 1903.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 100% 50%, 0% 50%;
}

#blog {
  background-image: url("../assets/Images/Background/Grupo 129.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

#news {
  background-image: url("../assets/Images/Blog/Enmascarar grupo 132.png"),
    url("../assets/Images/Blog/Elipse 117.png"),
    url("../assets/Images/Background/Grupo 691.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 0 40%, 15% -2%, 97% 93%;
  padding: 5% 0;
}

li {
  text-align: left;
}

#return {
  padding: 5% 13% 0;
}

#articles >>> .col-12.title {
  display: none !important;
}

#articles >>> .card {
  height: 94%;
}

#articles >>> .category {
  top: -15%;
}

.read-more {
  text-align: left;
  font: normal normal 600 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #ff0313;
  opacity: 1;
  display: block;
}

.news h1 {
  position: relative;
}

.news h1::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  bottom: -12px;
  right: 0;
  margin: auto;
}

.news h1 {
  text-align: right;
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  text-transform: uppercase;
  opacity: 1;
}

.new {
  height: 662px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #0000001a;
  border-radius: 20px;
  opacity: 1;
  margin-bottom: 100px;
}

@media (max-width: 1370px) {

  #articles {
    padding: 5% 0;
  }

  #icons {
    padding: 20% 85px 35% 85px;
  }

  #return {
    padding: 5% 4% 0;
  }
}

@media (max-width: 1024px) {
  #blog {
    background-image: url("../assets/Images/Coaches/Enmascarar grupo 217.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0 110%;
    padding: 0 0 25% 0;
  }

  #articles {
    background-image: none;
  }

  #articles >>> .category {
    top: -15%;
  }

  #top {
    padding: 0;
  }

  #top >>> .img-fluid {
    height: auto;
  }

  #top >>> .title-sec h2,
  #top >>> .text-sec p {
    text-align: center;
  }

  #top >>> #search-form {
    display: flex;
    justify-content: center;
  }

  #icons {
    background-image: url("../assets/Images/Blog/Enmascarar grupo 210.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
    padding: 25% 5%;
  }

  #icons >>> .bg-img {
    height: 93px;
    width: 93px;
    border-radius: 50%;
  }

  #icons >>> .title h3 {
    font: normal normal 800 20px/24px Montserrat;
    margin-bottom: 30px;
  }

  #icons >>> .title::after {
    bottom: 10px;
    left: 4%;
  }

  #icons >>> .name {
    margin-top: 15px;
  }

  #icons >>> .container-fluid {
    margin: 20px 0;
  }

  #icons >>> .name h2 {
    font: normal normal 500 12px/14px Montserrat;
  }

  #icons >>> .col-4 {
    padding: 0;
  }

  #articles >>> #cards {
    padding: 0 33px;
  }

  #articles >>> #article {
    padding: 0 15%;
  }

  #articles >>> .card-title {
    font: normal normal bold 16px/19px Montserrat;
  }

  #articles >>> .card-text {
    font: normal normal 500 14px/18px Montserrat;
    color: #000000;
  }

  #articles >>> .read-more {
    font: normal normal 600 13px/16px Montserrat;
  }

  #news {
    background-image: none;
  }

  .news {
    margin-bottom: 50px;
  }

  .news h1::after {
    left: 0;
    right: 0;
    margin: auto;
    bottom: -20px;
  }

  .news h1 {
    text-align: center;
    font: normal normal 800 20px/24px Montserrat;
    margin-right: 0;
  }
}

@media (max-width: 425px) {
  #top >>> .title-sec h2,
  #top >>> .text-sec p {
    text-align: left;
  }

  #top >>> #search-form {
    display: block;
  }

  .news::after {
    right: 40.5%;
    bottom: -20px;
  }

  #articles >>> #article {
    padding: 0;
  }
}

@media (max-width: 375px) {
  #icons,
  #blog {
    background-size: auto;
  }
}
</style>
